import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import('@/views/_general/Comingsoon')
const Error404 = () => import('@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import('@/views/_general/Login')
const Register = () => import('@/views/_general/Register')
const ResetPassword = () => import('@/views/_general/ResetPassword')

// Pages
const AuthProfile = () => import('@/views/_auth/AuthProfile')
const UpdatePasswordPage = () => import('@/views/_auth/UpdatePasswordPage')

const Home = () => import('@/views/_general/Home')

const UserList = () => import('@/views/Users/UserList')
const LockUserList = () => import('@/views/Users/LockUserList')
const UserProfile = () => import('@/views/Users/UserProfile')

const SupportiveSingle = () => import('@/views/Supports/SupportiveSingle')

const FAQList = () => import('@/views/Supports/FAQ')

const AuditTrailList = () => import('@/views/AuditTrails/AuditTrailList')
const AuditTrailProfile = () => import('@/views/AuditTrails/AuditTrailProfile')

const AdvertisementList = () => import('@/views/Advertisements/AdvertisementList')

const WhyChooseEzmobiList = () => import('@/views/WhyChooseEzmobis/WhyChooseEzmobiList')
const SubscriptionVsHirePurchaseList = () => import('@/views/SubscriptionVsHirePurchases/SubscriptionVsHirePurchaseList')
const EzmobiStepList = () => import('@/views/EzmobiSteps/EzmobiStepList')
const EzmobiIntroductionList = () => import('@/views/EzmobiIntroductions/EzmobiIntroductionList')
const AboutUsSingle = () => import('@/views/AboutUss/AboutUsSingle')
const StandardVsLitePackageSingle = () => import('@/views/StandardVsLitePackages/StandardVsLitePackageSingle')
const WhatIsTelematicDeviceSingle = () => import('@/views/WhatIsTelematicDevices/WhatIsTelematicDeviceSingle')
const MostEconomicalCarSingle = () => import('@/views/MostEconomicalCars/MostEconomicalCarSingle')
const MostPopularCarSingle = () => import('@/views/MostPopularCars/MostPopularCarSingle')
const LongTermSingle = () => import('@/views/OurSubscriptionServices/LongTermSingle')
const ShortTermSingle = () => import('@/views/OurSubscriptionServices/ShortTermSingle')

const PressReleaseList = () => import('@/views/PressReleases/PressReleaseList')
const EventList = () => import('@/views/Events/EventList')
const EventProfile = () => import('@/views/Events/EventProfile')
const SocialMediaList = () => import('@/views/SocialMedias/SocialMediaList')

const RoleList = () => import('@/views/Roles/RoleList')

const SettingPage = () => import('@/views/SystemVariables/SettingPage')
const SecuritySettingPage = () => import('@/views/SystemVariables/SecuritySettingPage')

const CarModelList = () => import('@/views/CarModels/CarModelList')
const CarModelProfile = () => import('@/views/CarModels/CarModelProfile')

const CarVariantProfile = () => import('@/views/CarVariants/CarVariantProfile')

const SubscriptionList = () => import('@/views/Subscriptions/SubscriptionList')
const SubscriptionProfile = () => import('@/views/Subscriptions/SubscriptionProfile')

const FeedbackList = () => import('@/views/Feedbacks/FeedbackList')

const FaqCategoryList = () => import('@/views/Faqs/FaqCategoryList')
const FaqCategoryProfile = () => import('@/views/Faqs/FaqCategoryProfile')

const PromoCodeList = () => import( '@/views/PromoCodes/PromoCodeList')

const OutletList = () => import( '@/views/Outlets/OutletList')

const CarAddOnList = () => import( '@/views/CarAddOns/CarAddOnList')

const ReportList = () => import( '@/views/Reports/ReportList')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [1, 5], title: 'Coming Soon', page_category: null } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home', page_category: null } },

	// user
	{ path: '/user/list', name: 'user.list', component: UserList, meta: { auth: true, title: 'User List', role: "Admin", page_category: "user" } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: true, title: 'Profile', page_category: null } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile', role: "Admin", page_category: "user" } },
	{ path: '/locked-user/list', name: 'locked-user.list', component: LockUserList, meta: { auth: true, title: 'Lock User List', role: "Admin", page_category: "locked-user" } },

	//role
	{ path: '/role/list', name: 'role.list', component: RoleList, meta: { auth: true, title: 'Role List', role: "Admin", page_category: "role" } },
	
	// support
	{ path: '/support/:type', name: 'support.supportive_list', component: SupportiveSingle, meta: { auth: true, title: 'Supportive Info', page_category: "support" } },
	{ path: '/support/terms-and-conditions', name: 'support.terms-and-conditions', component: SupportiveSingle, meta: { auth: true, title: 'Terms and Conditions', params: { type: 'terms-and-conditions' }, page_category: "support" } },
	{ path: '/support/terms-of-use', name: 'support.terms-of-use', component: SupportiveSingle, meta: { auth: true, title: 'Terms of Use', params: { type: 'terms-of-use' }, page_category: "support" } },
	{ path: '/support/privacy-policy', name: 'support.privacy-policy', component: SupportiveSingle, meta: { auth: true, title: 'Privacy Policy', params: { type: 'privacy-policy' }, page_category: "support" } },


	//audit trails
	{ path: '/audit-trail/list', name: 'audit-trail.list', component: AuditTrailList, meta: { auth: true, title: 'Audit Trail List', role: "Admin", page_category: "audit-trail" } },
	{ path: '/audit-trail/profile/:id', name: 'audit-trail.profile',component: AuditTrailProfile, meta: { auth: true, title: 'Audit Trail Profile', role: "Admin", page_category: "audit-trail" } },

	{ path: '/website-content/advertisement/list', name: 'advertisement.list', component: AdvertisementList, meta: { auth: true, title: 'Advertisement List', page_category: "advertisement" } },
	{ path: '/website-content/about-us/list', name: 'about-us.list', component: AboutUsSingle, meta: { auth: true, title: 'About Us', page_category: "about-us" } },
	{ path: '/website-content/introduction/list', name: 'ezmobi-introduction.list', component: EzmobiIntroductionList, meta: { auth: true, title: 'Introduction on Perodua EZ MOBi List', page_category: "ezmobi-introduction" } },
	{ path: '/website-content/our-subscription-service-long-term/list', name: 'our-subscription-service-long-term.list', component: LongTermSingle, meta: { auth: true, title: 'Our Subscription Service - Long Term Subscription', page_category: "our-subscription-service-long-term" } },
	{ path: '/website-content/our-subscription-service-short-term/list', name: 'our-subscription-service-short-term.list', component: ShortTermSingle, meta: { auth: true, title: 'Our Subscription Service - Short Term Subscription', page_category: "our-subscription-service-short-term" } },
	{ path: '/website-content/flipcard-one/list', name: 'flipcard-one.list', component: StandardVsLitePackageSingle, meta: { auth: true, title: 'Standard Package', page_category: "flipcard-one" } },
	{ path: '/website-content/flipcard-two/list', name: 'flipcard-two.list', component: MostEconomicalCarSingle, meta: { auth: true, title: 'Our Subscription Products Flipcard 2', page_category: "flipcard-two" } },
	{ path: '/website-content/flipcard-three/list', name: 'flipcard-three.list', component: MostPopularCarSingle, meta: { auth: true, title: 'Our Subscription Products Flipcard 3', page_category: "flipcard-three" } },
	{ path: '/website-content/flipcard-four/list', name: 'flipcard-four.list', component: WhatIsTelematicDeviceSingle, meta: { auth: true, title: 'Our Subscription Products Flipcard 4', page_category: "flipcard-four" } },
	{ path: '/website-content/subscription-step/list', name: 'ezmobi-step.list', component: EzmobiStepList, meta: { auth: true, title: 'Step to Get Your Car from Perodua EZ MOBi List', page_category: "ezmobi-step" } },
	{ path: '/website-content/subscription-vs-hp/list', name: 'subscription-vs-hp.list', component: SubscriptionVsHirePurchaseList, meta: { auth: true, title: 'Subscription Vs Hire Purchase List', page_category: "subscription-vs-hp" } },
	{ path: '/website-content/why-choose-ezmobi/list', name: 'why-choose-ezmobi.list', component: WhyChooseEzmobiList, meta: { auth: true, title: 'Value List', page_category: "why-choose-ezmobi" } },
	
	// { path: '/news/press-release/list', name: 'press-release.list', component: PressReleaseList, meta: { auth: true, title: 'Press Release List', page_category: "press-release" } },
	{ path: '/news/event/list', name: 'event.list', component: EventList, meta: { auth: true, title: 'Event List', page_category: "event" } },
	{ path: '/news/event/profile/:id', name: 'event.profile', component: EventProfile, meta: { auth: true, title: 'Event Profile', page_category: "event" } },
	{ path: '/news/social-media/list', name: 'social-media.list', component: SocialMediaList, meta: { auth: true, title: 'Social Media List', page_category: "social-media" } },

	{ path: '/car-model', name: 'car-model.list', component: CarModelList, meta: { auth: true, title: 'Car Model List', page_category: "car-model" } },
	{ path: '/car-model/profile/:id', name: 'car-model.profile', component: CarModelProfile, meta: { auth: true, title: 'Car Model Profile', page_category: "car-model" } },
	{ path: '/car-variant/profile/:id', name: 'car-model.variant-profile', component: CarVariantProfile, meta: { auth: true, title: 'Car Variant Profile', page_category: "car-model" } },

	{ path: '/interest-record/list', name: 'interest-record.list', component: SubscriptionList, meta: { auth: true, title: 'Subscription List', page_category: "interest-record" } },
	{ path: '/interest-record/profile/:id', name: 'interest-record.profile', component: SubscriptionProfile, meta: { auth: true, title: 'Subscription Profile', page_category: "interest-record" } },

	{ path: '/feedback/list', name: 'feedback.list', component: FeedbackList, meta: { auth: true, title: 'Feedback List', page_category: "feedback" } },

	{ path: '/support/faq/list', name: 'faq.list', component: FaqCategoryList, meta: { auth: true, title: 'FAQ Category List', page_category: "faq" } },
	{ path: '/support/faq/profile/:id', name: 'faq-category.profile', component: FaqCategoryProfile, meta: { auth: true, title: 'FAQ Category Profile', page_category: "faq" } },

	{ path: '/promo-code/list', name: 'promo-code.list', component: PromoCodeList, meta: { auth: true, title: 'Promo Code List', role: "Admin", page_category: "promo-code" } },

	{ path: '/outlet/list', name: 'outlet.list', component: OutletList, meta: { auth: true, title: 'Outlet List', role: "Admin", page_category: "outlet" } },

	{ path: '/car-add-on/list', name: 'car-add-on.list', component: CarAddOnList, meta: { auth: true, title: 'Car Add On List', role: "Admin", page_category: "car-add-on" } },

	{ path: '/report/list', name: 'report.list', component: ReportList, meta: { auth: true, title: 'Report List', role: "Admin", page_category: "report" } },

	{ path: '/setting', name: 'setting.page', component: SettingPage, meta: { auth: true, title: 'Settings', role: "Admin", page_category: "setting" } },
	
	{ path: '/security-setting', name: 'security-setting.page', component: SecuritySettingPage, meta: { auth: true, title: 'Security Settings', role: "Admin", page_category: "security-setting" } },

	{ path: '/auth/update-password', name: 'update-password.page', component: UpdatePasswordPage, meta: { auth: true, title: 'Update Password', page_category: null } },
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/register/:code', name: 'register', component: Register, meta: { auth: null, title: 'Register' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	base: process.env.VUE_APP_URL_PATH || '',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	return next()
});

export default router