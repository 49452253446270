import axios from 'axios';

let prefix = "/contact-us"

const FeedbackApi = {
	
	getFeedbackList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getFeedback(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	getFeedbackInfo(payload){
		return axios.get( prefix + "/get-contact-us-info", {params: payload})
	},
}

export default FeedbackApi;